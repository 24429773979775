<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h1 class="brand-text text-success ml-1">N9VA</h1>
      </b-link>

      <!-- Left Text-->
      <b-col
        lg="8"
        style="
          background: black;
          padding-top: 11rem;
          overflow: -moz-hidden-unscrollable;
        "
      >
        <div>
          <b-img
            fluid
            class="logo-image"
            src="@/assets/images/pages/login-image.png"
            alt="Login V2"
          />
        </div>
      </b-col>

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Iniciar sessão
          </b-card-title>
          <b-card-text class="mb-2">
            Faça login em sua conta e comece a aventura
          </b-card-text>

          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-overlay
                opacity="0.5"
                :show="login_overlay"
                spinner-variant="success"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="name@gmail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-overlay>

              <b-overlay
                opacity="0.5"
                :show="login_overlay"
                spinner-variant="success"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Senha</label>
                    <b-link @click="changePage('forgot-password')">
                      <small class="green-text">Esqueceu a senha?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-overlay>

              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  class="custom-control-success"
                  name="checkbox-1"
                >
                  Manter usuário
                </b-form-checkbox>
              </b-form-group>

              <div>
                <b-button
                  type="submit"
                  variant="success"
                  block
                  :disabled="login_overlay"
                  @click="doLogin()"
                >
                  Entrar
                </b-button>
              </div>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Novo na plataforma?</span>
            <b-link>
              <span class="green-text"> Crie uma conta</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BOverlay,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import Cookies from "js-cookie";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import api from "../service/api.js";

export default {
  components: {
    BRow,
    BOverlay,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      login_overlay: false,
      token_api: "",
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
    };
  },
  created() {
    if (
      Cookies.get("token_api") != null &&
      Cookies.get("token_api").length > 10
    ) {
      router.push("home");
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    changePage(location) {
      store.state.token_api = null;
      Cookies.set("token_api", null, { secure: true, sameSite: "Lax" });
      router.push(location);
    },
    async doLogin() {
      var self = this;
      self.login_overlay = true;
      let data = JSON.stringify({
        strategy: "local",
        email: self.userEmail,
        password: self.password,
      });

      const headers = {
        "Content-Type": "application/json",
      };

      await axios
        .post("https://tekie.cloud:3031/authentication", data, { headers })

        .then(function(response) {
          store.state.token_api = response.data.accessToken;
          Cookies.set("token_api", response.data.accessToken, {
            secure: true,
            sameSite: "Lax",
          });
          window.localStorage.setItem(
            "refreshToken",
            response.data.refreshToken
          );
          store.state.refresh_interval = setInterval(
            api.refresh_token,
            10000
          );
          store.state.refresh_interval = true;

          router.push("home");
        })
        .catch(function(e) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Usuário ou senha incorretos",
              icon: "XIcon",
              variant: "danger",
            },
          });
        });

      self.login_overlay = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.green-text {
  color: $success;
}

.logo-image {
  transform: scale(1.25);
  position: absolute;
  bottom: 12vh;
}
</style>
